import { DialogOverlay } from '@reach/dialog'
import { type ChangeEvent, type FC, Fragment, memo, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import ResetIcon from 'assets/svg/app/reset.svg'
import SettingsIcon from 'assets/svg/app/settings.svg'
import Button from 'components/Button'
import { BaseCard } from 'components/Card/style'

import Switch from 'components/Switch'
import { Body } from 'components/Text'
import { FlexDivCol, FlexDivRowCentered } from 'components/layout/flex'
import { MOBILE_FOOTER_HEIGHT, zIndex } from 'constants/ui'
import useClickOutside from 'hooks/useClickOutside'
import useWindowSize from 'hooks/useWindowSize'
import { useRouter } from 'next/router'
import { selectOneClickTradingSelected, selectPerpsProvider } from 'state/futures/common/selectors'
import { setPreferences } from 'state/futures/reducer'
import {
	selectShowFavorites,
	selectShowFunding,
	selectShowHistory,
	selectShowOrderLines,
	selectShowPositionLines,
	selectShowRealizedPnL,
	selectShowTestnets,
} from 'state/futures/selectors'
import { selectCondOrdersEnabled } from 'state/futures/snxPerpsV3/selectors'
import { TradingModes } from 'state/futures/types'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import media from 'styles/media'
import { providerIsCrossMargin } from 'utils/futures'

const PreferencesPanel: FC = memo(() => {
	const { t } = useTranslation()
	const router = useRouter()
	const dispatch = useAppDispatch()
	const perpsProvider = useAppSelector(selectPerpsProvider)
	const showHistory = useAppSelector(selectShowHistory)
	const showFavorites = useAppSelector(selectShowFavorites)
	const showPositionLines = useAppSelector(selectShowPositionLines)
	const showOrderLines = useAppSelector(selectShowOrderLines)
	const showRealizedPnL = useAppSelector(selectShowRealizedPnL)
	const showTestnets = useAppSelector(selectShowTestnets)
	const showFunding = useAppSelector(selectShowFunding)
	const isOneClickSelected = useAppSelector(selectOneClickTradingSelected)
	const condOrdersEnabled = useAppSelector(selectCondOrdersEnabled)

	const [open, setOpen] = useState(false)
	const { deviceType } = useWindowSize()
	const isMobile = useMemo(() => deviceType === 'mobile', [deviceType])

	const isMarketsPage = useMemo(() => router.pathname.includes('market'), [router.pathname])

	const { ref } = useClickOutside(() => setOpen(false))
	const handleReset = useCallback(
		() =>
			dispatch(
				setPreferences({
					showRealizedPnL: true,
					showHistory: true,
					showOrderLines: true,
					showPositionLines: true,
					showTestnets: true,
					showFunding: true,
					tradingMode: TradingModes.ONE_CLICK,
				})
			),
		[dispatch]
	)

	const toggleOneClickTrading = useCallback(
		(_: ChangeEvent<HTMLInputElement>) =>
			dispatch(
				setPreferences({
					tradingMode: isOneClickSelected ? TradingModes.DEFAULT : TradingModes.ONE_CLICK,
				})
			),
		[dispatch, isOneClickSelected]
	)

	const toggleHistory = useCallback(
		(e: ChangeEvent<HTMLInputElement>) =>
			dispatch(setPreferences({ showHistory: e.target.checked })),
		[dispatch]
	)

	const toggleFavorites = useCallback(
		(e: ChangeEvent<HTMLInputElement>) =>
			dispatch(setPreferences({ showFavorites: e.target.checked })),
		[dispatch]
	)

	const togglePositionLines = useCallback(
		(e: ChangeEvent<HTMLInputElement>) =>
			dispatch(setPreferences({ showPositionLines: e.target.checked })),
		[dispatch]
	)

	const toggleOrderLines = useCallback(
		(e: ChangeEvent<HTMLInputElement>) =>
			dispatch(setPreferences({ showOrderLines: e.target.checked })),
		[dispatch]
	)

	const toggleRealizedPnL = useCallback(
		(e: ChangeEvent<HTMLInputElement>) =>
			dispatch(setPreferences({ showRealizedPnL: e.target.checked })),
		[dispatch]
	)

	const toggleTestnets = useCallback(
		(e: ChangeEvent<HTMLInputElement>) =>
			dispatch(setPreferences({ showTestnets: e.target.checked })),
		[dispatch]
	)

	const toggleFunding = useCallback(
		(e: ChangeEvent<HTMLInputElement>) =>
			dispatch(setPreferences({ showFunding: e.target.checked })),
		[dispatch]
	)

	const PREFERENCES = [
		{
			title: t('common.preferences.one-click-trading'),
			option: <Switch checked={isOneClickSelected} onChange={toggleOneClickTrading} />,
		},
		{
			title: t('common.preferences.trade-history'),
			option: <Switch checked={showHistory} onChange={toggleHistory} />,
		},
		{
			title: t('common.preferences.favorites'),
			option: <Switch checked={showFavorites} onChange={toggleFavorites} />,
		},
		{
			title: t('common.preferences.chart.title'),
			subOptions: [
				{
					title: t('common.preferences.chart.positions'),
					option: <Switch checked={showPositionLines} onChange={togglePositionLines} />,
				},
				{
					title: t('common.preferences.chart.active-orders'),
					option: <Switch checked={showOrderLines} onChange={toggleOrderLines} />,
					hide: providerIsCrossMargin(perpsProvider) && !condOrdersEnabled,
				},
			],
		},
		{
			title: t('common.preferences.position-table.title'),
			subOptions: [
				{
					title: t('common.preferences.position-table.funding'),
					option: <Switch checked={showFunding} onChange={toggleFunding} />,
				},
				{
					title: t('common.preferences.position-table.rpnl'),
					option: <Switch checked={showRealizedPnL} onChange={toggleRealizedPnL} />,
				},
			],
		},
		{
			title: t('common.preferences.show-testnets'),
			option: <Switch checked={showTestnets} onChange={toggleTestnets} />,
		},
	]

	return (
		(isMarketsPage || isMobile) && (
			<>
				<MenuButton onClick={() => setOpen((x) => !x)} noOutline>
					<SettingsIcon width={17} height={18} />
				</MenuButton>
				{open && (
					<StyledDialogOverlay>
						<PanelContainer ref={ref}>
							<CardsContainer>
								{PREFERENCES.map(({ title, option, subOptions }) => (
									<Fragment key={`group-${title}`}>
										{subOptions ? (
											<FlexDivCol rowGap="10px">
												<Body color="primary" weight="bold">
													{title}
												</Body>
												<FlexDivCol rowGap="10px">
													{subOptions
														.filter(({ hide }) => !hide)
														.map(({ title, option }) => (
															<FlexDivRowCentered key={`suboption-${title}`}>
																<Body color="secondary">{title}</Body>
																{option}
															</FlexDivRowCentered>
														))}
												</FlexDivCol>
											</FlexDivCol>
										) : (
											<FlexDivRowCentered>
												<Body color="primary" weight="bold">
													{title}
												</Body>
												{option}
											</FlexDivRowCentered>
										)}
										<SectionSeparator />
									</Fragment>
								))}
								<ResetContainer justifyContent="center" columnGap="8px" onClick={handleReset}>
									<ResetIcon width={12} height={12} />
									<Body size="small" color="preview" weight="semiBold">
										{t('common.preferences.reset')}
									</Body>
								</ResetContainer>
							</CardsContainer>
						</PanelContainer>
					</StyledDialogOverlay>
				)}
			</>
		)
	)
})

const ResetContainer = styled(FlexDivRowCentered)`
	cursor: pointer;
	svg {
		path {
			color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.preview};
		}
	}
`

const StyledDialogOverlay = styled(DialogOverlay)`
	z-index: 10;
	background: rgba(0, 0, 0, 0.5);
	${media.lessThan('md')`
		z-index: ${zIndex.MOBILE_FOOTER};
		background: transparent;
	`}
`
const SectionSeparator = styled.div`
	height: 1px;
	background-color: ${(props) => props.theme.colors.selectedTheme.newTheme.border.color};
`

const MenuButton = styled(Button)`
	display: grid;
	place-items: center;
	height: 41px;
	width: 41px;
	padding: 0px;

	svg {
		path {
			color: ${(props) => props.theme.colors.selectedTheme.newTheme.tabs.position.inactive};
		}
	}
`

const PanelContainer = styled.div`
	z-index: 100;
	position: absolute;
	top: 15px;
	right: 15px;

	${media.lessThan('mdUp')`
		padding: 15px;
		top: auto;
		right: 0px;
		bottom: ${MOBILE_FOOTER_HEIGHT};
	`}

	${media.greaterThan('mdUp')`
		margin-top: 56px;
	`}
`

const CardsContainer = styled(BaseCard)`
	display: flex;
	flex-direction: column;
	width: 330px;
	row-gap: 15px;
	border-radius: 8px;
	padding: 15px;

	${media.lessThan('md')`
		width: 360px;
	`}
`

export default PreferencesPanel
