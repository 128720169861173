import { POLL_FREQUENCY_INTERVALS } from 'constants/defaults'
import { useCallback, useMemo } from 'react'
import { FuturesTab } from 'sections/futures/UserInfo/UserInfoTabs'
import { selectAppIsFocused } from 'state/app/selectors'
import { useAppSelector, usePollAction } from 'state/hooks'
import { selectWallet } from 'state/wallet/selectors'
import { fetchOrderHistory, fetchTradeHistory } from '../actions'
import { selectPerpsProvider, selectProviderNetwork } from '../common/selectors'
import { selectAccountContext, selectSelectedTab } from '../selectors'

export const useFetchTabData = () => {
	const selectedPerpsProvider = useAppSelector(selectPerpsProvider)
	const accountId = useAppSelector(selectAccountContext)
	const wallet = useAppSelector(selectWallet)
	const networkId = useAppSelector(selectProviderNetwork)
	const selectedTab = useAppSelector(selectSelectedTab)

	const focused = useAppSelector(selectAppIsFocused)
	const intervals = useMemo(() => {
		return POLL_FREQUENCY_INTERVALS[focused ? 'active' : 'inactive']
	}, [focused])

	const fetchTradeHistoryAction = useCallback(
		() => fetchTradeHistory({ providers: [selectedPerpsProvider] }),
		[selectedPerpsProvider]
	)

	usePollAction('fetchTradeHistry', fetchTradeHistoryAction, {
		dependencies: [networkId, selectedPerpsProvider, accountId],
		disabled: !wallet || selectedTab !== FuturesTab.TRADES,
		intervalTime: intervals.mid,
	})

	const fetchOrderHistoryAction = useCallback(
		() => fetchOrderHistory([selectedPerpsProvider]),
		[selectedPerpsProvider]
	)

	usePollAction('fetchOrderHistory', fetchOrderHistoryAction, {
		dependencies: [networkId, wallet, accountId, selectedPerpsProvider],
		intervalTime: intervals.mid,
		disabled: !wallet || selectedTab !== FuturesTab.ORDER_HISTORY,
	})
}
