import BaseSwitch, { type SwitchProps } from '@mui/material/Switch'
import { type FC, memo } from 'react'
import styled from 'styled-components'

const Switch: FC<SwitchProps> = memo((props) => (
	<SwitchContainer>
		<BaseSwitch disableRipple {...props} />
	</SwitchContainer>
))

const SwitchContainer = styled.div`
	& .MuiSwitch-root {
		width: 30px;
		height: 18px;
		padding: 0px;

		& .Mui-checked {
			transform: translateX(12px);
			color: ${(props) => props.theme.colors.common.palette.neutral.n0};

			& + .MuiSwitch-track {
				background-color: ${(props) => props.theme.colors.selectedTheme.newTheme.badge.primary.background};
				opacity: 1;
				border: 0px;
			}
		}
	}

	& .MuiSwitch-switchBase {
		padding: 0px;
		margin: 2px;
		transition-duration: 300ms;
		color: ${(props) => props.theme.colors.common.palette.neutral.n60};
	}

	& .MuiSwitch-thumb {
		box-sizing: border-box;
		width: 14px;
		height: 14px;
	}

	& .MuiSwitch-track {
		border-radius: 9px;
		border: 1px solid ${(props) => props.theme.colors.selectedTheme.newTheme.border.color};
		background-color: ${(props) =>
			props.theme.colors.selectedTheme.newTheme.tabs.position.background};
		opacity: 1;
	}
`

export default Switch
