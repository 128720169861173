import { v3MarketIdByMarketAsset } from '@kwenta/sdk/constants'
import type { SnxV3NetworkIds } from '@kwenta/sdk/types'
import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector, useFetchAction } from 'state/hooks'
import sdk from 'state/sdk'
import { providerIsCrossMargin } from 'utils/futures'
import logError from 'utils/logError'
import { selectMarketAsset, selectPerpsProvider, selectProviderNetwork } from '../common/selectors'
import { selectAccountContext } from '../selectors'
import {
	fetchCrossMarginAccountLiquidations,
	fetchPerpsV3GlobalLiquidations,
	refetchCrossMarginAccountLiquidations,
	refetchPerpsV3GlobalLiquidations,
} from '../snxPerpsV3/actions'

export const useFetchV3MarketLiquidations = () => {
	const dispatch = useAppDispatch()
	const provider = useAppSelector(selectPerpsProvider)
	const marketAsset = useAppSelector(selectMarketAsset)
	const networkId = useAppSelector(selectProviderNetwork)
	const { accountId } = useAppSelector(selectAccountContext)

	const marketId = useMemo(() => {
		if (!providerIsCrossMargin(provider)) return
		return BigInt(v3MarketIdByMarketAsset(networkId as SnxV3NetworkIds, marketAsset))
	}, [networkId, marketAsset, provider])

	useFetchAction(fetchPerpsV3GlobalLiquidations, {
		dependencies: [provider, marketAsset],
		disabled: !providerIsCrossMargin(provider),
	})

	useFetchAction(fetchCrossMarginAccountLiquidations, {
		dependencies: [networkId, accountId, provider],
		disabled: !accountId || !providerIsCrossMargin(provider),
	})

	useEffect(() => {
		if (!providerIsCrossMargin(provider)) return
		const unsubscribeLiquidation = sdk.snxPerpsV3.watchAccountLiquidatedEvents({
			chainId: networkId as SnxV3NetworkIds,
			onLogs: (logs) => {
				if (logs.some((log) => log.args.accountId === BigInt(accountId ?? ''))) {
					dispatch(refetchCrossMarginAccountLiquidations())
				}

				if (logs.some((log) => log.args.marketId === marketId)) {
					dispatch(refetchPerpsV3GlobalLiquidations())
				}
			},
			onError: (error) => {
				logError(error)
			},
		})

		return () => {
			unsubscribeLiquidation()
		}
	}, [dispatch, accountId, marketId, networkId, provider])
}
