import styled, { css } from 'styled-components'

export const BaseCard = styled.div<{ $noPadding?: boolean }>`
	background: ${(props) => props.theme.colors.selectedTheme.newTheme.containers.cards.background};
	padding: 25px;
	border-radius: 15px;
	border: 1px solid ${(props) => props.theme.colors.selectedTheme.newTheme.border.color};

	.value {
		font-weight: 700;
		font-feature-settings: 'tnum';
		font-size: 26px;
		color: ${(props) => props.theme.colors.selectedTheme.yellow};
		margin-top: 10px;
	}

	.label {
		font-size: 13px;
		color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primary};
	}

	${(props) =>
		props.$noPadding &&
		css`
			padding: 0;
			overflow: hidden;
		`}
`
