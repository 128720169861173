import { v3MarketIdByMarketAsset } from '@kwenta/sdk/constants'
import type { SnxV3NetworkIds } from '@kwenta/sdk/types'
import { useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector, useFetchAction, usePollAction } from 'state/hooks'
import sdk from 'state/sdk'
import { providerIsCrossMargin } from 'utils/futures'
import { fetchGlobalTradeHistory } from '../actions'
import { selectMarketAsset, selectPerpsProvider, selectProviderNetwork } from '../common/selectors'
import { refetchGlobalTradeHistory } from '../snxPerpsV3/actions'

export const useFetchMarketTrades = () => {
	const dispatch = useAppDispatch()

	const provider = useAppSelector(selectPerpsProvider)
	const networkId = useAppSelector(selectProviderNetwork)
	const asset = useAppSelector(selectMarketAsset)

	const marketId = useMemo(() => {
		if (!providerIsCrossMargin(provider)) return
		return BigInt(v3MarketIdByMarketAsset(networkId as SnxV3NetworkIds, asset))
	}, [networkId, asset, provider])

	const fetchGlobal = useCallback(
		() => fetchGlobalTradeHistory({ providers: [provider] }),
		[provider]
	)

	// Use polling for isolated margin, websocket for cross margin
	usePollAction('fetchGlobalTradeHistory', fetchGlobal, {
		dependencies: [provider, asset],
		disabled: providerIsCrossMargin(provider),
	})

	// Fetch data on initial (selected market change)
	useFetchAction(fetchGlobal, {
		dependencies: [provider, asset],
		disabled: !providerIsCrossMargin(provider),
	})

	useEffect(() => {
		if (!providerIsCrossMargin(provider) || !marketId) return

		const chainId = networkId as SnxV3NetworkIds

		const unsubscribe = sdk.snxPerpsV3.watchOrderSettledEvents({
			chainId,
			marketId,
			onLogs: () => {
				dispatch(refetchGlobalTradeHistory())
			},
			onError: () => {},
		})

		return () => {
			unsubscribe()
		}
	}, [dispatch, networkId, marketId, provider])
}
