import type { SnxV3NetworkIds } from '@kwenta/sdk/types'
import { POLL_FREQUENCY_INTERVALS } from 'constants/defaults'
import { useCallback, useEffect, useMemo } from 'react'
import { selectAppIsFocused } from 'state/app/selectors'
import { useAppDispatch, useAppSelector, useFetchAction, usePollAction } from 'state/hooks'
import sdk from 'state/sdk'
import { providerIsCrossMargin } from 'utils/futures'
import logError from 'utils/logError'
import { fetchHighFrequencyAccountData, fetchLowFrequencyAccountData } from '../actions'
import {
	selectMarketsLength,
	selectPerpsProvider,
	selectProviderNetwork,
} from '../common/selectors'
import { selectAccountContext } from '../selectors'
import { selectCrossMarginMarginInfo } from '../snxPerpsV3/selectors'

export const useFetchAccountData = () => {
	const dispatch = useAppDispatch()

	const provider = useAppSelector(selectPerpsProvider)
	const marketsLength = useAppSelector(selectMarketsLength)
	const { accountId } = useAppSelector(selectAccountContext)
	const networkId = useAppSelector(selectProviderNetwork)
	const { availableMargin } = useAppSelector(selectCrossMarginMarginInfo)

	const focused = useAppSelector(selectAppIsFocused)
	const intervals = useMemo(() => {
		return POLL_FREQUENCY_INTERVALS[focused ? 'active' : 'inactive']
	}, [focused])

	const fetchHighFrequencyAccountDataAction = useCallback(
		() => fetchHighFrequencyAccountData([provider]),
		[provider]
	)

	// We run it one time on initial load
	useFetchAction(fetchHighFrequencyAccountDataAction, {
		dependencies: [provider, accountId],
		disabled: !marketsLength || !accountId,
	})

	usePollAction('fetchHighFrequencyAccountData', fetchHighFrequencyAccountDataAction, {
		intervalTime: intervals.high,
		dependencies: [provider, accountId],
		disabled:
			!marketsLength ||
			!accountId ||
			// We probably dont wanna fetch a lot of high frequency data if user doesn't have balance
			(providerIsCrossMargin(provider) && Number(availableMargin) === 0),
	})

	const fetchLowFrequencyAccountDataAction = useCallback(
		() => fetchLowFrequencyAccountData([provider]),
		[provider]
	)

	usePollAction('fetchLowFrequencyAccountData', fetchLowFrequencyAccountDataAction, {
		intervalTime: intervals.low,
		dependencies: [marketsLength, provider, accountId],
		disabled: !marketsLength,
	})

	useEffect(() => {
		const subscriptions: (() => void)[] = []

		if (accountId && providerIsCrossMargin(provider)) {
			subscriptions.push(
				sdk.snxPerpsV3.watchCollateralChanges({
					chainId: networkId as SnxV3NetworkIds,
					accountId: BigInt(accountId),
					onLogs: () => {
						dispatch(fetchHighFrequencyAccountData([provider]))
					},
					onError: (error) => {
						logError(error)
					},
				})
			)

			subscriptions.push(
				sdk.snxPerpsV3.watchOrderSettledEvents({
					chainId: networkId as SnxV3NetworkIds,
					accountId: BigInt(accountId),
					onLogs: () => {
						dispatch(fetchHighFrequencyAccountData([provider]))
					},
					onError: (error) => {
						logError(error)
					},
				})
			)
		}

		return () => {
			subscriptions.forEach((subscription) => subscription())
		}
	}, [accountId, networkId, provider, dispatch])
}
